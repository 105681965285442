<div class="login-wrapper">
  <div class="login-form">
    <h2>{{'NEWSLETTER.UNSUBSCRIBE_TITLE' | translate}}</h2>

    <span class="mb-20"><b>{{'NEWSLETTER.SET_YOUR_PREFERENCES_BOLD' | translate}}</b> {{'NEWSLETTER.SET_YOUR_PREFERENCES' | translate}}</span>

    <div class="mb-20">
      <p-checkbox [(ngModel)]="unsubscribedNewsletters.marketing" [binary]="true" inputId="marketing" class="mr-10" />
      <span><b>{{'NEWSLETTER.MARKETING_UNSUBSCRIBE_BOLD' | translate}}</b> - {{'NEWSLETTER.MARKETING_UNSUBSCRIBE' | translate}}</span>
    </div>

    <div class="mb-20">
      <p-checkbox [(ngModel)]="unsubscribedNewsletters.info" [binary]="true" inputId="marketing" class="mr-10" />
      <span><b>{{'NEWSLETTER.INFO_UNSUBSCRIBE_BOLD' | translate}}</b> - {{'NEWSLETTER.INFO_UNSUBSCRIBE' | translate}} <a href="https://easy-task.ai/" target="_blank">Easy-Task.ai</a></span>
    </div>
    

    <p-button (onClick)="onSubmit()" label="{{'COMMON.SEND' | translate}}"></p-button>
  </div>
</div>
<p-toast />