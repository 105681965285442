import { animate, state, style, transition, trigger } from "@angular/animations";
import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faAnglesLeft, faAnglesRight, faAtom, faFlask, faGraduationCap, faUsers, faRocket, faPenNib, IconDefinition, faFingerprint, faHouse, faUser, faPowerOff, faGear } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth.service";
import { NavBarService } from "src/app/services/nav-bar.service";

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatIconModule,
    TranslateModule
  ],
  animations: [
    trigger('slideMenu', [
      state('false', style({
        width: '280px',
      })),
      state('true', style({
        width: '80px',
      })),
      transition('true <=> false', animate('400ms ease-in-out'))
    ]),
    trigger('resizeText', [
      state('false', style({
        width: '200px',
      })),
      state('true', style({
        width: '0',
      })),
      transition('true <=> false', animate('400ms ease-in-out'))
    ])
  ]
})

export class NavBarComponent implements OnInit {
  @Input() darkModeActive: boolean = true;
  faAnglesLeft = faAnglesLeft;
  faAnglesRight = faAnglesRight;
  faAtom = faAtom;
  faFlask = faFlask;
  faUsers = faUsers;
  faRocket = faRocket;
  faPenNib = faPenNib;
  faPowerOff = faPowerOff;
  faGraduationCap = faGraduationCap;
  closedNav: boolean = false;
  navItems: IntNavItem[];
  navIcons: { [key: string]: IconDefinition } = {
    faHouse: faHouse,
    faGraduationCap: faGraduationCap,
    faRocket: faRocket,
    faUsers: faUsers,
    faPenNib: faPenNib,
    faFingerprint: faFingerprint,
    faUser: faUser,
    faCircleQuestion: faCircleQuestion,
    faGear: faGear
  };
  isMenuOpen: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private navBarService: NavBarService
  ) { }

  ngOnInit(): void {
    this.navBarService.getNavList().subscribe(res => {
      const navItems: IntNavItem[] = [];
      res.forEach(navItem => {
        navItems.push({
          redirectTo: navItem.redirect_to,
          literal: navItem.literal,
          icon: this.navIcons[navItem.icon]
        });
      });
      this.navItems = navItems;
    })
  }

  toggleNav() {
    this.closedNav = !this.closedNav;
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu(): void {
    this.isMenuOpen = false;
  }

  navigateTo(path: string, toggle?: boolean) {
    if (toggle) this.toggleMenu();

    this.router.navigateByUrl(path);
  }

  logout() {
    this.authService.logout();
  }
}

export interface IntNavItem {
  redirectTo: string;
  literal: string;
  icon: IconDefinition;
}
