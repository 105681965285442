import { Injectable } from "@angular/core";
import { IntDropdownData } from "../components/form-generator/form-generator.component";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { dropdownEnums } from "../enums/dropdown-options";
import { countries, languages } from "../enums/countries";
import { Clipboard } from '@angular/cdk/clipboard';


@Injectable({
    providedIn: 'root',
  })
  export class UtilsService {

    constructor(
      private translate: TranslateService,
      private router: Router,
      private clipboard: Clipboard
    ) {}

    translateDropdownOptions(options: IntDropdownData[]): IntDropdownData[] {
      const translatedOptions = options.map(option => {
        option.name = this.translate.instant(option.name);
        return option;
      })
      return translatedOptions;
    }

    getDropdownOptionsEnum(key: string | undefined): IntDropdownData[] {
      if (!key) return [];
      const translatedOptions = dropdownEnums[key]?.map(option => {
        option.name = this.translate.instant(option.name);
        return option;
      })
      return translatedOptions;
    }

    navigateTo(path: string) {
      this.router.navigateByUrl(path);
    }

    getCountriesDropdown(): IntDropdownData[] {
      const dropdownData: IntDropdownData[] = [];
      countries.forEach(country => {
        const iso3 = country["alpha-3"];
        const literal = `COUNTRIES.${iso3}`;
        dropdownData.push({
          id: iso3,
          name: this.translate.instant(literal)
        });
      })
      return dropdownData;
    }

    getLanguagesDropdown(): IntDropdownData[] {
      const dropdownData: IntDropdownData[] = [];
      languages.forEach(lang => {
        const literal = `LANGUAGES.${lang.id}`;
        dropdownData.push({
          id: lang.id,
          name: this.translate.instant(literal)
        });
      })
      return dropdownData;
    }

    copyToClipboard(text: string) {
      this.clipboard.copy(text);
    }

    replaceMarkdownLinks(text: string) {
      const regex = /\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g;
      return text.replace(regex, '<a href="$2" target="_blank">$1</a>');
    }
  }
