import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private utilsService: UtilsService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (error.error.error === 'invalid_request') {
            this.authService.logout();
          }
          return this.authService.refreshToken().pipe(
            switchMap(() => {
              const newToken = localStorage.getItem('access_token');
              const clonedRequest = req.clone({
                setHeaders: { Authorization: `Bearer ${newToken}` },
              });
              return next.handle(clonedRequest);
            }),
            catchError((refreshError) => {
              this.authService.logout();
              return throwError(refreshError);
            })
          );
        } else if (error.status === 403) {
          if(error.error.error) {
            this.utilsService.navigateTo('/');
          }
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
