import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {

  constructor(
    private http: HttpClient
  ) {}

  getNavList(): Observable<IntNavItemResponse[]> {
    const apiUrl = `${environment.api_url}nav/get`;
    return this.http.get<IntNavItemResponse[]>(apiUrl);
  }
}

export interface IntNavItemResponse {
  redirect_to: string;
  literal: string;
  icon: string;
}
