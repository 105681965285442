<div class="all-page" [ngClass]="{ 'dark': darkModeActive}">
    <nav-bar class="nav-bar" *ngIf="isLoggedIn$ | async"></nav-bar>
    <div class="shared-view">
        <router-outlet></router-outlet>
    </div>
</div>

<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [header]="modalHeader | translate"
  (onHide)="onModalHide()"
  [draggable]="false"
  [resizable]="false"
>
  <ng-template #modalContainer></ng-template>
</p-dialog>
