import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";


@Component({
    selector: 'verify-email',
    templateUrl: './verify-email.component.html',
    standalone: true,
    imports: [],
    providers: [MessageService]
})

export class VerifyEmailComponent {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private messageServide: MessageService
  ) {}

  ngOnInit(): void {
    const verificationUrl = this.route.snapshot.queryParamMap.get('verification_url');

    if (verificationUrl) {
      this.http.get(verificationUrl, { withCredentials: true }).subscribe({
        next: () => {
          this.router.navigate(['/login']);
        },
        error: () => {
          this.router.navigate(['/login']);
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }
}
