import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatIconModule } from '@angular/material/icon';
import { ApiInterceptor } from './core/interceptors/api-interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { QuillModule } from 'ngx-quill';
import { CommonModule } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { DialogModule } from 'primeng/dialog';
import { SubscriptionInterceptor } from './core/interceptors/subscriptions.interceptor';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
      AppComponent
    ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      ReactiveFormsModule,
      NavBarComponent,
      FontAwesomeModule,
      MatIconModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      }),
      QuillModule.forRoot(),
      CommonModule,
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: ['178.128.41.226'],
          disallowedRoutes: ['178.128.41.226/api/auth/login', '178.128.41.226/api/auth/register']
        },
      }),
      DialogModule
    ],
    providers: [
      { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
      provideAnimationsAsync(),
      importProvidersFrom(MarkdownModule.forRoot()),
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: SubscriptionInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
  })
export class AppModule { }
