import { Component, ComponentRef, EventEmitter, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { faMoon, faSun } from '@fortawesome/free-regular-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { ModalService } from './services/modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  darkModeActive: boolean = true;
  faMoon = faMoon;
  faSun = faSun;
  isLoggedIn$: Observable<boolean> = new Observable<boolean>;
  selectedLanguage = 'es';

  displayModal = false;
  @ViewChild('modalContainer', { read: ViewContainerRef })
  modalContainer: ViewContainerRef;
  private componentRef: ComponentRef<any>;
  modalHeader: string = '';

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private modalService: ModalService
  ) {
    this.translateService.setDefaultLang(this.selectedLanguage);
    this.translateService.use(this.selectedLanguage);
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.initModal();
  }

  selectLanguage(lang: string) {
    this.translateService.use(lang);
  }

  switchLightDark() {
    this.darkModeActive = !this.darkModeActive;
  }

  initModal() {
    this.modalService.modalVisibility.subscribe((visible) => {
      this.displayModal = visible;
      if (visible) {
        this.loadDynamicComponent();
      } else {
        this.modalContainer.clear();
      }
    });
  }

  loadDynamicComponent() {
    const modalData = this.modalService.getModalData();
    if (modalData) {
      const { component, data, outputs, header } = modalData;
      this.modalHeader = header || '';
      this.componentRef = this.modalContainer.createComponent(component);

      if (data) {
        Object.assign(this.componentRef.instance, data);
      }

      if (outputs) {
        Object.keys(outputs).forEach((outputKey) => {
          if (this.componentRef.instance[outputKey] instanceof EventEmitter) {
            this.componentRef.instance[outputKey].subscribe(outputs[outputKey]);
          }
        });
      }
    }
  }

  onModalHide() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
