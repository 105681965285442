import { IntDropdownData } from "../components/form-generator/form-generator.component"

export const dropdownEnums: {
    [key:string]: IntDropdownData[]
} = {
    tone: [
        { id: 'authoritative', name: 'ENUMS.AUTHORITATIVE' },
        { id: 'caring', name: 'ENUMS.CARING' },
        { id: 'casual', name: 'ENUMS.CASUAL' },
        { id: 'cheerfull', name: 'ENUMS.CHEERFULL' },
        { id: 'coarse', name: 'ENUMS.COARSE' },
        { id: 'conservative', name: 'ENUMS.CONSERVATIVE' },
        { id: 'conversational', name: 'ENUMS.CONVERSATIONAL' },
        { id: 'creative', name: 'ENUMS.CREATIVE' },
        { id: 'dry', name: 'ENUMS.DRY' },
        { id: 'edgy', name: 'ENUMS.EDGY' },
        { id: 'enthusiastic', name: 'ENUMS.ENTHUSIASTIC' },
        { id: 'expository', name: 'ENUMS.EXPOSITORY' },
        { id: 'formal', name: 'ENUMS.FORMAL' },
        { id: 'frank', name: 'ENUMS.FRANK' },
        { id: 'friendly', name: 'ENUMS.FRIENDLY' },
        { id: 'fun', name: 'ENUMS.FUN' },
        { id: 'humorous', name: 'ENUMS.HUMOROUS' },
        { id: 'informative', name: 'ENUMS.INFORMATIVE' },
        { id: 'irreverent', name: 'ENUMS.IRREVERENT' },
        { id: 'journalistic', name: 'ENUMS.JOURNALISTIC' },
        { id: 'matter of fact', name: 'ENUMS.MATTER_OF_FACT' },
        { id: 'nostalgic', name: 'ENUMS.NOSTALGIC' },
        { id: 'objective', name: 'ENUMS.OBJECTIVE' },
        { id: 'passionate', name: 'ENUMS.PASSIONATE' },
        { id: 'poetic', name: 'ENUMS.POETIC' },
        { id: 'playful', name: 'ENUMS.PLAYFUL' },
        { id: 'professional', name: 'ENUMS.PROFESSIONAL' },
        { id: 'provocative', name: 'ENUMS.PROVOCATIVE' },
        { id: 'quirky', name: 'ENUMS.QUIRKY' },
        { id: 'respectful', name: 'ENUMS.RESPECTFUL' },
        { id: 'romantic', name: 'ENUMS.ROMANTIC' },
        { id: 'sarcastic', name: 'ENUMS.SARCASTIC' },
        { id: 'serious', name: 'ENUMS.SERIOUS' },
        { id: 'smart', name: 'ENUMS.SMART' },
        { id: 'subjective', name: 'ENUMS.SUBJECTIVE' },
        { id: 'sympathetic', name: 'ENUMS.SYMPATHETIC' },
        { id: 'trendy', name: 'ENUMS.TRENDY' },
        { id: 'trustworthy', name: 'ENUMS.TRUSTWORTHY' },
        { id: 'unapologetic', name: 'ENUMS.UNAPOLOGETIC' },
        { id: 'upbeat', name: 'ENUMS.UPBEAT' },
        { id: 'witty', name: 'ENUMS.WITTY' }
    ],
    writing_style: [
        { id: 'academic', name: 'ENUMS.ACADEMIC' },
        { id: 'analytical', name: 'ENUMS.ANALYTICAL'},
        { id: 'argumentative', name: 'ENUMS.ARGUMENTATIVE' },
        { id: 'converastional', name: 'ENUMS.CONVERSATIONAL' },
        { id: 'creative', name: 'ENUMS.CREATIVE' },
        { id: 'critical', name: 'ENUMS.CRITICAL' },
        { id: 'descriptive', name: 'ENUMS.DESCRIPTIVE' },
        { id: 'epigrammatic', name: 'ENUMS.EPIGRAMMATIC' },
        { id: 'epistolary', name: 'ENUMS.EPISTOLARY' },
        { id: 'expository', name: 'ENUMS.EXPOSITORY' },
        { id: 'informative', name: 'ENUMS.INFORMATIVE' },
        { id: 'intructive', name: 'ENUMS.INSTRUCTIVE' },
        { id: 'journalistic', name: 'ENUMS.JOURNALISTIC' },
        { id: 'metaphorical', name: 'ENUMS.METAPHORICAL' },
        { id: 'narrative', name: 'ENUMS.NARRATIVE' },
        { id: 'persuasive', name: 'ENUMS.PERSUASIVE' },
        { id: 'poetic', name: 'ENUMS.POETIC' },
        { id: 'satirical', name: 'ENUMS.SATIRICAL' },
        { id: 'technical', name: 'ENUMS.TECHNICAL'  }
    ],
    months: [
        { id: '01', name: 'ENUMS.JANUARY' },
        { id: '02', name: 'ENUMS.FEBRUARY' },
        { id: '03', name: 'ENUMS.MARCH' },
        { id: '04', name: 'ENUMS.APRIL' },
        { id: '05', name: 'ENUMS.MAY' },
        { id: '06', name: 'ENUMS.JUNE' },
        { id: '07', name: 'ENUMS.JULY' },
        { id: '08', name: 'ENUMS.AUGUST' },
        { id: '09', name: 'ENUMS.SEPTEMBER' },
        { id: '10', name: 'ENUMS.OCTOBER' },
        { id: '11', name: 'ENUMS.NOVEMBER' },
        { id: '12', name: 'ENUMS.DECEMBER' }
    ],
    levelOfThree: [
        { id: 'basic', name: 'ENUMS.BASICA'},
        { id: 'intermediate', name: 'ENUMS.INTERMEDIATEA'},
        { id: 'advanced', name: 'ENUMS.ADVANCEDA'}
    ],
    citationStyles: [
        { id: 'vancouver', name: 'ENUMS.VANCOUVER'},
        { id: 'harvard', name: 'ENUMS.HARVARD'},
        { id: 'APA', name: 'ENUMS.APA'},
        { id: 'MLA', name: 'ENUMS.MLA'},
        { id: 'IEEE', name: 'ENUMS.IEEE'},
        { id: 'Chicago', name: 'ENUMS.CHICAGO'},
        { id: 'UNE ISO 690:2013', name: 'ENUMS.UNE.ISO.690:2013'}
    ],
    optionsNumber: [
        { id: '2', name: 'ENUMS.2'},
        { id: '3', name: 'ENUMS.3'},
        { id: '4', name: 'ENUMS.4'},
        { id: '5', name: 'ENUMS.5'}
    ],
    binaryStatus: [
      { id: 'active', name: 'ENUMS.ACTIVE'},
      { id: 'inactive', name: 'ENUMS.INACTIVE'}
    ],
    editorFormats: [
      {id: 'indice', name: 'ENUMS.OUTLINE'},
      {id: 'blog post', name: 'ENUMS.BLOG_POST'},
      {id: 'lista de tareas', name: 'ENUMS.TO_DO_LIST'},
      {id: 'ensayo', name: 'ENUMS.ESSAY'},
      {id: 'historia creativa', name: 'ENUMS.CREATIVE_STORY'},
      {id: 'comunicado de prensa', name: 'ENUMS.PRESS_RELEASE'},
      {id: 'post de redes sociales', name: 'ENUMS.SOCIAL_MEDIA_POST'},
      {id: 'poema', name: 'ENUMS.POEM'}
    ],
    languages: [
      { id: 'AA', name: 'LANGUAGES.AA'},
      { id: 'AB', name: 'LANGUAGES.AB'},
      { id: 'AE', name: 'LANGUAGES.AE'},
      { id: 'AF', name: 'LANGUAGES.AF'},
      { id: 'AK', name: 'LANGUAGES.AK'},
      { id: 'AM', name: 'LANGUAGES.AM'},
      { id: 'AN', name: 'LANGUAGES.AN'},
      { id: 'AR', name: 'LANGUAGES.AR'},
      { id: 'AS', name: 'LANGUAGES.AS'},
      { id: 'AV', name: 'LANGUAGES.AV'},
      { id: 'AY', name: 'LANGUAGES.AY'},
      { id: 'AZ', name: 'LANGUAGES.AZ'},
      { id: 'BA', name: 'LANGUAGES.BA'},
      { id: 'BE', name: 'LANGUAGES.BE'},
      { id: 'BG', name: 'LANGUAGES.BG'},
      { id: 'BH', name: 'LANGUAGES.BH'},
      { id: 'BI', name: 'LANGUAGES.BI'},
      { id: 'BM', name: 'LANGUAGES.BM'},
      { id: 'BN', name: 'LANGUAGES.BN'},
      { id: 'BO', name: 'LANGUAGES.BO'},
      { id: 'BR', name: 'LANGUAGES.BR'},
      { id: 'BS', name: 'LANGUAGES.BS'},
      { id: 'CA', name: 'LANGUAGES.CA'},
      { id: 'CE', name: 'LANGUAGES.CE'},
      { id: 'CH', name: 'LANGUAGES.CH'},
      { id: 'CO', name: 'LANGUAGES.CO'},
      { id: 'CR', name: 'LANGUAGES.CR'},
      { id: 'CS', name: 'LANGUAGES.CS'},
      { id: 'CU', name: 'LANGUAGES.CU'},
      { id: 'CV', name: 'LANGUAGES.CV'},
      { id: 'CY', name: 'LANGUAGES.CY'},
      { id: 'DA', name: 'LANGUAGES.DA'},
      { id: 'DE', name: 'LANGUAGES.DE'},
      { id: 'DV', name: 'LANGUAGES.DV'},
      { id: 'DZ', name: 'LANGUAGES.DZ'},
      { id: 'EE', name: 'LANGUAGES.EE'},
      { id: 'EL', name: 'LANGUAGES.EL'},
      { id: 'EN', name: 'LANGUAGES.EN'},
      { id: 'EO', name: 'LANGUAGES.EO'},
      { id: 'ES', name: 'LANGUAGES.ES'},
      { id: 'ET', name: 'LANGUAGES.ET'},
      { id: 'EU', name: 'LANGUAGES.EU'},
      { id: 'FA', name: 'LANGUAGES.FA'},
      { id: 'FF', name: 'LANGUAGES.FF'},
      { id: 'FI', name: 'LANGUAGES.FI'},
      { id: 'FJ', name: 'LANGUAGES.FJ'},
      { id: 'FO', name: 'LANGUAGES.FO'},
      { id: 'FR', name: 'LANGUAGES.FR'},
      { id: 'FY', name: 'LANGUAGES.FY'},
      { id: 'GA', name: 'LANGUAGES.GA'},
      { id: 'GD', name: 'LANGUAGES.GD'},
      { id: 'GL', name: 'LANGUAGES.GL'},
      { id: 'GN', name: 'LANGUAGES.GN'},
      { id: 'GU', name: 'LANGUAGES.GU'},
      { id: 'GV', name: 'LANGUAGES.GV'},
      { id: 'HA', name: 'LANGUAGES.HA'},
      { id: 'HE', name: 'LANGUAGES.HE'},
      { id: 'HI', name: 'LANGUAGES.HI'},
      { id: 'HO', name: 'LANGUAGES.HO'},
      { id: 'HR', name: 'LANGUAGES.HR'},
      { id: 'HT', name: 'LANGUAGES.HT'},
      { id: 'HU', name: 'LANGUAGES.HU'},
      { id: 'HY', name: 'LANGUAGES.HY'},
      { id: 'HZ', name: 'LANGUAGES.HZ'},
      { id: 'IA', name: 'LANGUAGES.IA'},
      { id: 'ID', name: 'LANGUAGES.ID'},
      { id: 'IE', name: 'LANGUAGES.IE'},
      { id: 'IG', name: 'LANGUAGES.IG'},
      { id: 'II', name: 'LANGUAGES.II'},
      { id: 'IK', name: 'LANGUAGES.IK'},
      { id: 'IO', name: 'LANGUAGES.IO'},
      { id: 'IS', name: 'LANGUAGES.IS'},
      { id: 'IT', name: 'LANGUAGES.IT'},
      { id: 'IU', name: 'LANGUAGES.IU'},
      { id: 'JA', name: 'LANGUAGES.JA'},
      { id: 'JV', name: 'LANGUAGES.JV'},
      { id: 'KA', name: 'LANGUAGES.KA'},
      { id: 'KG', name: 'LANGUAGES.KG'},
      { id: 'KI', name: 'LANGUAGES.KI'},
      { id: 'KJ', name: 'LANGUAGES.KJ'},
      { id: 'KK', name: 'LANGUAGES.KK'},
      { id: 'KL', name: 'LANGUAGES.KL'},
      { id: 'KM', name: 'LANGUAGES.KM'},
      { id: 'KN', name: 'LANGUAGES.KN'},
      { id: 'KO', name: 'LANGUAGES.KO'},
      { id: 'KR', name: 'LANGUAGES.KR'},
      { id: 'KS', name: 'LANGUAGES.KS'},
      { id: 'KU', name: 'LANGUAGES.KU'},
      { id: 'KV', name: 'LANGUAGES.KV'},
      { id: 'KW', name: 'LANGUAGES.KW'},
      { id: 'KY', name: 'LANGUAGES.KY'},
      { id: 'LA', name: 'LANGUAGES.LA'},
      { id: 'LB', name: 'LANGUAGES.LB'},
      { id: 'LG', name: 'LANGUAGES.LG'},
      { id: 'LI', name: 'LANGUAGES.LI'},
      { id: 'LN', name: 'LANGUAGES.LN'},
      { id: 'LO', name: 'LANGUAGES.LO'},
      { id: 'LT', name: 'LANGUAGES.LT'},
      { id: 'LU', name: 'LANGUAGES.LU'},
      { id: 'LV', name: 'LANGUAGES.LV'},
      { id: 'MG', name: 'LANGUAGES.MG'},
      { id: 'MH', name: 'LANGUAGES.MH'},
      { id: 'MI', name: 'LANGUAGES.MI'},
      { id: 'MK', name: 'LANGUAGES.MK'},
      { id: 'ML', name: 'LANGUAGES.ML'},
      { id: 'MN', name: 'LANGUAGES.MN'},
      { id: 'MR', name: 'LANGUAGES.MR'},
      { id: 'MS', name: 'LANGUAGES.MS'},
      { id: 'MT', name: 'LANGUAGES.MT'},
      { id: 'MY', name: 'LANGUAGES.MY'},
      { id: 'NA', name: 'LANGUAGES.NA'},
      { id: 'NB', name: 'LANGUAGES.NB'},
      { id: 'ND', name: 'LANGUAGES.ND'},
      { id: 'NE', name: 'LANGUAGES.NE'},
      { id: 'NG', name: 'LANGUAGES.NG'},
      { id: 'NL', name: 'LANGUAGES.NL'},
      { id: 'NN', name: 'LANGUAGES.NN'},
      { id: 'NO', name: 'LANGUAGES.NO'},
      { id: 'NR', name: 'LANGUAGES.NR'},
      { id: 'NV', name: 'LANGUAGES.NV'},
      { id: 'NY', name: 'LANGUAGES.NY'},
      { id: 'OC', name: 'LANGUAGES.OC'},
      { id: 'OJ', name: 'LANGUAGES.OJ'},
      { id: 'OM', name: 'LANGUAGES.OM'},
      { id: 'OR', name: 'LANGUAGES.OR'},
      { id: 'OS', name: 'LANGUAGES.OS'},
      { id: 'PA', name: 'LANGUAGES.PA'},
      { id: 'PI', name: 'LANGUAGES.PI'},
      { id: 'PL', name: 'LANGUAGES.PL'},
      { id: 'PS', name: 'LANGUAGES.PS'},
      { id: 'PT', name: 'LANGUAGES.PT'},
      { id: 'QC', name: 'LANGUAGES.QC'},
      { id: 'RM', name: 'LANGUAGES.RM'},
      { id: 'RN', name: 'LANGUAGES.RN'},
      { id: 'RO', name: 'LANGUAGES.RO'},
      { id: 'RU', name: 'LANGUAGES.RU'},
      { id: 'RW', name: 'LANGUAGES.RW'},
      { id: 'SA', name: 'LANGUAGES.SA'},
      { id: 'SC', name: 'LANGUAGES.SC'},
      { id: 'SD', name: 'LANGUAGES.SD'},
      { id: 'SE', name: 'LANGUAGES.SE'},
      { id: 'SG', name: 'LANGUAGES.SG'},
      { id: 'SI', name: 'LANGUAGES.SI'},
      { id: 'SK', name: 'LANGUAGES.SK'},
      { id: 'SL', name: 'LANGUAGES.SL'},
      { id: 'SM', name: 'LANGUAGES.SM'},
      { id: 'SN', name: 'LANGUAGES.SN'},
      { id: 'SO', name: 'LANGUAGES.SO'},
      { id: 'SQ', name: 'LANGUAGES.SQ'},
      { id: 'SR', name: 'LANGUAGES.SR'},
      { id: 'SS', name: 'LANGUAGES.SS'},
      { id: 'ST', name: 'LANGUAGES.ST'},
      { id: 'SU', name: 'LANGUAGES.SU'},
      { id: 'SV', name: 'LANGUAGES.SV'},
      { id: 'SW', name: 'LANGUAGES.SW'},
      { id: 'TA', name: 'LANGUAGES.TA'},
      { id: 'TE', name: 'LANGUAGES.TE'},
      { id: 'TG', name: 'LANGUAGES.TG'},
      { id: 'TH', name: 'LANGUAGES.TH'},
      { id: 'TI', name: 'LANGUAGES.TI'},
      { id: 'TK', name: 'LANGUAGES.TK'},
      { id: 'TL', name: 'LANGUAGES.TL'},
      { id: 'TN', name: 'LANGUAGES.TN'},
      { id: 'TO', name: 'LANGUAGES.TO'},
      { id: 'TR', name: 'LANGUAGES.TR'},
      { id: 'TS', name: 'LANGUAGES.TS'},
      { id: 'TT', name: 'LANGUAGES.TT'},
      { id: 'TW', name: 'LANGUAGES.TW'},
      { id: 'TY', name: 'LANGUAGES.TY'},
      { id: 'UG', name: 'LANGUAGES.UG'},
      { id: 'UK', name: 'LANGUAGES.UK'},
      { id: 'UR', name: 'LANGUAGES.UR'},
      { id: 'UZ', name: 'LANGUAGES.UZ'},
      { id: 'VI', name: 'LANGUAGES.VI'},
      { id: 'VO', name: 'LANGUAGES.VO'},
      { id: 'WA', name: 'LANGUAGES.WA'},
      { id: 'WO', name: 'LANGUAGES.WO'},
      { id: 'XH', name: 'LANGUAGES.XH'},
      { id: 'YI', name: 'LANGUAGES.YI'},
      { id: 'YO', name: 'LANGUAGES.YO'},
      { id: 'ZA', name: 'LANGUAGES.ZA'},
      { id: 'ZH', name: 'LANGUAGES.ZH'},
      { id: 'ZU', name: 'LANGUAGES.ZU'}
    ]
}
