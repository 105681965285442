import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";


@Component({
    selector: 'verify-email',
    templateUrl: './verify-email.component.html',
    standalone: true,
    imports: [],
    providers: []
})

export class VerifyEmailComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  
  constructor(
    private readonly route: ActivatedRoute,
    private readonly http: HttpClient,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    const verificationUrl = this.route.snapshot.queryParamMap.get('verification_url');

    if (verificationUrl) {
      this.http.get(verificationUrl, { withCredentials: true })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.router.navigate(['/login']);
          },
          error: () => {
            this.router.navigate(['/login']);
          }
        }
      );
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
