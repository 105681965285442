import { Component, ComponentRef, EventEmitter, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { faMoon, faSun } from '@fortawesome/free-regular-svg-icons';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { ModalService } from './services/modal.service';
import { NavigationEnd, Router } from '@angular/router';
declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  darkModeActive: boolean = true;
  faMoon = faMoon;
  faSun = faSun;
  isLoggedIn$: Observable<boolean> = new Observable<boolean>;
  selectedLanguage = 'es';

  displayModal = false;
  @ViewChild('modalContainer', { read: ViewContainerRef })
  modalContainer: ViewContainerRef;
  private componentRef: ComponentRef<any>;
  modalHeader: string = '';

  constructor(
    private readonly authService: AuthService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly router: Router
  ) {
    const browserLang = navigator.language.split('-')[0];

    this.selectedLanguage = browserLang === 'en' ? 'en' : 'es';

    this.translateService.setDefaultLang(this.selectedLanguage);
    this.translateService.use(this.selectedLanguage);
    const navEndEvents = router.events.pipe(
      filter((event): event is NavigationEnd  => event instanceof NavigationEnd)
    );
    navEndEvents
    .pipe(takeUntil(this.destroy$))
    .subscribe((event: NavigationEnd) => {
      if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
        const decodedUrl = decodeURIComponent(event.urlAfterRedirects);
        gtag('config', 'G-L3FP1LD25G', {
          'page_path': decodedUrl
        });
      }
      
    });
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.initModal();
  }

  selectLanguage(lang: string) {
    this.translateService.use(lang);
  }


  switchLightDark() {
    this.darkModeActive = !this.darkModeActive;
  }

  initModal() {
    this.modalService.modalVisibility
    .pipe(takeUntil(this.destroy$))
    .subscribe((visible) => {
      this.displayModal = visible;
      if (visible) {
        this.loadDynamicComponent();
      } else {
        this.modalContainer.clear();
      }
    });
  }

  loadDynamicComponent() {
    const modalData = this.modalService.getModalData();
    if (modalData) {
      const { component, data, outputs, header } = modalData;
      this.modalHeader = header || '';
      this.componentRef = this.modalContainer.createComponent(component);

      if (data) {
        Object.assign(this.componentRef.instance, data);
      }

      if (outputs) {
        Object.keys(outputs).forEach((outputKey) => {
          if (this.componentRef.instance[outputKey] instanceof EventEmitter) {
            this.componentRef.instance[outputKey]
            .pipe(takeUntil(this.destroy$))
            .subscribe(outputs[outputKey]);
          }
        });
      }
    }
  }

  onModalHide() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
