import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})

export class UserService {
  private apiUrl = environment.api_url + 'user/';

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  getProfile(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}profile`);
  }

  getTokensAndLang(): Observable<{tokens: number, lang: string}> {
    return this.http.get<any>(`${this.apiUrl}get-tokens-lang`);
  }

  updateProfile(params: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}update`, params);
  }

  uploadAvatar(params: any) {
    return this.http.post<any>(`${this.apiUrl}avatar`, params);
  }

  register(params: IntUserRegister): Observable<any> {
    return this.http.post<any>(`${environment.api_url}register`, params);
  }

  changePasswordEamil(params: {email: string}): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}reset-password-email`, params);
  }

  changePassword(params: {email: string, token: string, password: string, password_confirmation: string}): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}reset-password`, params);
  }

  getUnsubscribedCategories(email: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}unsubscribed-newsletters/${email}`);
  }

  setUnsubscribedCategories(params: {email: string, subscriptions: {marketing: boolean, info: boolean}}): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}unsubscribed-newsletters`, params);
  }
}

export interface IntUserRegister {
  name: string;
  email: string;
  surnames: string;
  birthdate: string;
  password: string;
  language: string;
}
