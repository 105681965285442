import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class SubscriptionInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 402) {       
          if (typeof error.error === 'string') {
            const errorParsed = JSON.parse(error.error);
            if(errorParsed.error === 'SUBSCRIPTION_NOT_ACTIVE') {
              this.router.navigateByUrl('/user?status=past_due');
            }
            if(errorParsed.error === 'NOT_ENOUGH_TOKENS') {
              this.router.navigateByUrl('/user?status=no_tokens');
            }              
          } else {
            if(error?.error?.error === 'NOT_ENOUGH_TOKENS') {
              this.router.navigateByUrl('/user?status=no_tokens');
            }
            if(error?.error?.error === 'SUBSCRIPTION_NOT_ACTIVE') {
              this.router.navigateByUrl('/user?status=past_due');
            }
          }
          
        }
        return throwError(error);
      })
    );
  }
}
