import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { IntUserRegister, UserService } from "src/app/services/user.service";
import { UtilsService } from "src/app/services/utils.service";

export function passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
  const userPassword = control.get('userPassword')?.value;
  const confirmPassword = control.get('confirmPassword')?.value;

  if (userPassword !== confirmPassword) {
    return { passwordsMismatch: true };
  }

  return null;
}

export function passwordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const hasMinLength = value.length >= 8;

    const passwordValid = hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && hasMinLength;

    return !passwordValid
      ? {
          passwordStrength: {
            hasUpperCase,
            hasLowerCase,
            hasNumber,
            hasSpecialChar,
            hasMinLength,
          },
        }
      : null;
  };
}

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
})
export class RegisterViewComponent implements OnInit{
  registerForm: FormGroup;

  constructor(
    public utilsService: UtilsService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private translate: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      firstName: ['', Validators.required],
      surnames: ['', Validators.required],
      userEmail: ['', [Validators.required, Validators.email]],
      birthdate: [null, [Validators.required]],
      userPassword: ['', [Validators.required, passwordStrengthValidator()]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: passwordMatchValidator // Aquí aplicamos el validador personalizado
    });
    this.registerForm.reset();
    setTimeout(() => {
      this.registerForm.patchValue({
        userEmail: '',
        userPassword: '',
        confirmPassword: ''
      });
    }, 50);
  }

  removeReadonly(field: string): void {
    const input = document.querySelector(`input[formControlName="${field}"]`);
    if (input) {
      input.removeAttribute('readonly');
    }
  }

  onSubmit() {
    const formValue = this.registerForm.value;
    const birthdate = formValue.birthdate;
    let formattedBirthdate = null;
    if (birthdate) {
      const date = new Date(birthdate);
      formattedBirthdate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
    const params: IntUserRegister = {
      name: formValue.firstName,
      surnames: formValue.surnames,
      email: formValue.userEmail,
      birthdate: formattedBirthdate as string,
      password: formValue.userPassword
    };
    this.userService.register(params).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('OK_KO.REGISTER_OK'),
          life: 2000
        });
        this.utilsService.navigateTo('/login');
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('OK_KO.REGISTER_KO'),
          life: 2000
        });
      }
    )
  }
}
