import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavListenerService {
  private actionSource = new Subject<string>();
  action$ = this.actionSource.asObservable();

  updateTokensLang() {
    this.actionSource.next('updateTokens');
  }
}