import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private googleRedirectUrl = `${environment.api_base_url}/auth/google/redirect`;
  private googleCallbackUrl = `${environment.api_url}auth/google/callback`;

  constructor(
    private http: HttpClient,
    private router: Router,
    private modalService: ModalService
  ) {}


  get isLoggedIn() {
      return this.loggedIn.asObservable();
  }

  login(params: {email:string, password: string}): Observable<IntOauthToken> {
      const requestParams = {
          grant_type: 'password',
          client_id: environment.client_id,
          client_secret: environment.client_secret,
          username: params.email,
          password: params.password,
          scope: '*'
      }
      return this.http.post<IntOauthToken>(`${environment.api_url}login`, requestParams).pipe(
        tap((response: any) => {
          this.storeTokens(response.access_token, response.refresh_token);
        }),
        catchError(this.handleError)
      );
  }

  loginWithGoogle() {
    window.location.href = this.googleRedirectUrl;
  }

  setUserStatus(loggedIn: boolean) {
    this.isRefreshingToken = false;
    this.loggedIn.next(loggedIn);
  }

  private isRefreshingToken = false;
  private tokenRefreshSubscribers: Array<(token: string) => void> = [];

  refreshToken(): Observable<any> {
    const refreshToken = localStorage.getItem('refresh_token');

    const requestParams = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      scope: ''
  }
    if (this.isRefreshingToken) {
      return new Observable(observer => {
        this.tokenRefreshSubscribers.push((token: string) => {
          observer.next(token);
          observer.complete();
        });
      });
    } else {
      this.isRefreshingToken = true;

      return this.http.post(`${environment.api_url}login`, requestParams).pipe(
        tap((response: any) => {
          this.isRefreshingToken = false;
          this.storeTokens(response.access_token, response.refresh_token);
          this.notifyTokenRefreshSubscribers(response.access_token);
        }),
        catchError((error) => {
          this.isRefreshingToken = false;
          this.logout();
          return of(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    }
  }

  logout(): void {
    this.modalService.closeModal();
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    this.setUserStatus(false);
    this.router.navigate(['/login']);
  }

  saveToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  private storeTokens(accessToken: string, refreshToken: string): void {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  private notifyTokenRefreshSubscribers(newToken: string): void {
    this.tokenRefreshSubscribers.forEach(callback => callback(newToken));
    this.tokenRefreshSubscribers = []; // Vaciar la cola
  }
}


export interface IntOauthToken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}
