<div class="login-wrapper">
  <div class="login-form" *ngIf="!showRecover">
    <h2>{{'COMMON.LOGIN' | translate}}</h2>

    <!--<div class="social-login">
      <p-button [outlined]="true" (click)="loginWithGoogle()">
        <img class="social-login__icon" src="/assets/images/google.svg" alt="google login" />{{'COMMON.CONTINUE_GOOGLE'
        | translate}}
      </p-button>

      <div class="separator">
        <span>{{'COMMON.OR' | translate}}</span>
      </div>
    </div>-->

    <form [formGroup]="loginForm">
      <div class="form-group">
        <label for="email">{{'COMMON.EMAIL' | translate}}</label>
        <input type="text" pInputText formControlName="email" />
      </div>
      <div class="form-group">
        <label for="password">{{'COMMON.PASSWORD' | translate}}</label>
        <p-password formControlName="password" [feedback]="false" [toggleMask]="true"></p-password>
      </div>

      <p-button class="login-button" (onClick)="onSubmit()" label="{{'COMMON.DO_LOGIN' | translate}}"></p-button>
      <div class="login-register">
        <p>{{'REGISTER.NOT_REGISTERED_YET' | translate}}</p>
        <a (click)="utilsService.navigateTo('/register')"
          class="p-button p-button-link font-bold">{{'REGISTER.REGISTER_HERE' | translate}}</a>
      </div>
      <div class="login-register">
        <a (click)="toggleRecover()" class="p-button p-button-link font-bold">{{'COMMON.CANT_ACCESS_ACCOUNT' |
          translate}}</a>
      </div>
    </form>
  </div>

  <div class="login-form recover-panel" *ngIf="showRecover">
    <p-button class="back-button" severity="danger" icon="pi pi-times" [rounded]="true" [outlined]="true" (click)="toggleRecover()"/>
    <h2>{{'COMMON.RECOVER_PASS' | translate}} </h2>
    <p>{{'COMMON.PROBLES_TO_LOGIN' | translate}}</p>
    <form [formGroup]="recoverForm">
      <div class="form-group">
        <label for="email">{{'COMMON.EMAIL' | translate}}</label>
        <input type="text" pInputText formControlName="recover" />
      </div>
      <p-button class="login-button" (onClick)="recoverPass()" label="{{'COMMON.RECOVER' | translate}}" [loading]="loadingRecover"></p-button>
    </form>
  </div>
  <p-toast />