<nav class="navbar" [ngClass]="{ close: closedNav }" [@slideMenu]="closedNav">
  <div class="navbar__item">
    <div class="navbar__item-icon" (click)="navigateTo('/')">
      <img *ngIf="darkModeActive" class="logo-img" src="/assets/images/logo_degradado.png" alt="image" />
      <img *ngIf="!darkModeActive" class="logo-img" src="/assets/images/LOGO(NEGRO).svg" alt="image" />
    </div>
    <div class="navbar__item-text cursor-pointer" (click)="navigateTo('/')" [@resizeText]="closedNav">
      <img class="logo-img" src="/assets/images/logo_titulo_degradado.png" alt="image" />
    </div>
  </div>

  <div class="navbar__item" [@slideMenu]="closedNav">
    <div class="navbar__item-icon cursor-pointer" (click)="toggleNav()">
      <fa-icon *ngIf="!closedNav" [icon]="faAnglesLeft"></fa-icon>
      <fa-icon *ngIf="closedNav" [icon]="faAnglesRight"></fa-icon>
    </div>
    <div class="navbar__item-text  cursor-pointer" (click)="toggleNav()" [@resizeText]="closedNav">
      <span class="title nav">{{'COMMON.COLLAPSE' | translate}}</span>
    </div>
  </div>

  <div class="navbar__item" *ngFor="let item of navItems">
    <div class="navbar__item-icon cursor-pointer" (click)="navigateTo(item.redirectTo)">
      <fa-icon [icon]="item.icon"></fa-icon>
    </div>
    <div class="navbar__item-text cursor-pointer" (click)="navigateTo(item.redirectTo)" [@resizeText]="closedNav">
      <span class="title nav">{{item.literal| translate}}</span>
    </div>
  </div>

  <div class="navbar__item">
    <div class="navbar__item-icon cursor-pointer" (click)="logout()">
      <fa-icon [icon]="faPowerOff"></fa-icon>
    </div>
    <div class="navbar__item-text cursor-pointer" (click)="logout()" [@resizeText]="closedNav">
      <span class="title nav">{{'COMMON.LOGOUT' | translate}}</span>
    </div>
  </div>
  <div *ngIf="showBoxes" class="navbar_items navbar__languages">
    <p-dropdown 
      [options]="languaugesOptions"
      [(ngModel)]="selectedLenguage"
      optionLabel="name"
      optionValue="id"
      (onChange)="changeLanguage()"
    >
      <ng-template let-country pTemplate="item">
        <div class="flex align-items-center gap-2">
          <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
            [class]="'flag flag-' + country.id.toLowerCase()" style="width: 18px" />
          <div>{{ country.name }}</div>
        </div>
      </ng-template>
      <ng-template pTemplate="selectedItem" let-selectedOption>
        <div class="flex align-items-center gap-2">
          <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
            [class]="'flag flag-' + selectedLenguage" style="width: 18px" />
          <div>{{ selectedOption.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div *ngIf="showBoxes" class="tokens-count">
    <span>Tokens: {{tokens}}</span>
    <p-button severity="danger" label="{{'COMMON.UPGRADE_PLAN' | translate}}" (onClick)="upgradePlan()"
      class="upgrade-plan-button" [style]="{'width': '100%'}" />
  </div>
</nav>
<nav class="mobile-navbar">
  <div class="navbar-container">
    <div class="logo" (click)="navigateTo('/')">
      <img class="logo-icon" src="/assets/images/logo_degradado.png" alt="image" />
      <img class="logo-text" src="/assets/images/logo_titulo_degradado.png" alt="image" />
    </div>
    <div (click)="toggleMenu()" id="nav-icon2" [ngClass]="{'open': isMenuOpen}">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <ul class="menu" [class.open]="isMenuOpen">
    <li *ngFor="let item of navItems">
      <div class="mobile-nav-div" (click)="navigateTo(item.redirectTo, true)">
        <fa-icon [icon]="item.icon"></fa-icon><span class="title nav">{{item.literal| translate}}</span>
      </div>
    </li>
    <li>
      <div class="mobile-nav-div" (click)="logout()">
        <fa-icon [icon]="faPowerOff"></fa-icon><span class="title nav">{{'COMMON.LOGOUT' | translate}}</span>
      </div>
    </li>
    <li class="mobile-nav-languaegs">
      <p-dropdown 
        [options]="languaugesOptions"
        [(ngModel)]="selectedLenguage"
        optionLabel="name"
        optionValue="id"
        (onChange)="changeLanguage()"
      >
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
              [class]="'flag flag-' + country.id.toLowerCase()" style="width: 18px" />
            <div>{{ country.name }}</div>
          </div>
        </ng-template>
        <ng-template pTemplate="selectedItem" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
              [class]="'flag flag-' + selectedLenguage" style="width: 18px" />
            <div>{{ selectedOption.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </li>
    <li>
      <div class="tokens-count-mobile mobile-nav-div">
        <p-button severity="danger" label="{{'COMMON.UPGRADE_PLAN' | translate}}" (onClick)="upgradePlan()"
          class="upgrade-plan-button" />
        <span>Tokens: {{tokens}}</span>
      </div>
    </li>
  </ul>
</nav>