<div class="login-wrapper">
  <div class="login-form">
    <h2>{{'COMMON.RESET_PASSWORD' | translate}}</h2>
    <form [formGroup]="resetPasswordForm">

      <div class="form-group">
        <label for="userPassword">{{'COMMON.PASSWORD' | translate}}</label>
        <p-password formControlName="userPassword" [toggleMask]="true" autocomplete="off"
          placeholder="{{'COMMON.PASSWORD' | translate}}" promptLabel="{{'REGISTER.ENTER_PASSWORD' | translate}}"
          weakLabel="{{'REGISTER.WEAK_PASSWORD' | translate}}" mediumLabel="{{'REGISTER.MEDIUM_PASSWORD' | translate}}"
          strongLabel="{{'REGISTER.STRONG_PASSWORD' | translate}}">
          <ng-template pTemplate="footer">
            <p-divider />
            <p class="mt-2">{{"REGISTER.REQUIREMENTS" | translate}}</p>
            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
              <li>{{"REGISTER.UPPERCASE_LOWERCASE_REQUIREMENT" | translate}}</li>
              <li>{{"REGISTER.NUMBER_REQUIREMENT" | translate}}</li>
              <li>{{"REGISTER.SYMBOL_REQUIREMENT" | translate}}</li>
              <li>{{"REGISTER.MINIMUM_8_CHAR" | translate}}</li>
            </ul>
          </ng-template>

        </p-password>
        <small
          *ngIf="resetPasswordForm.get('userPassword')?.errors?.['required'] && resetPasswordForm.get('userPassword')?.touched"
          class="p-error">
          {{'ERRORS.REQUIRED_FIELD' | translate}}
        </small>
        <small *ngIf="resetPasswordForm.get('userPassword')?.errors?.['passwordStrength']" class="p-error">
          {{'ERRORS.PASSWORD_STRENGTH' | translate}}
        </small>
      </div>

      <div class="form-group">
        <label for="confirmPassword">{{'COMMON.CONFIRM_PASSWORD' | translate}}</label>
        <p-password formControlName="confirmPassword" placeholder="{{'COMMON.CONFIRM_PASSWORD' | translate}}"
          [feedback]="false" [toggleMask]="true"></p-password>
        <small
          *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['required'] && resetPasswordForm.get('confirmPassword')?.touched"
          class="p-error">
          {{'ERRORS.REQUIRED_FIELD' | translate}}
        </small>
        <small
          *ngIf="resetPasswordForm.errors?.['passwordsMismatch'] && (resetPasswordForm.get('userPassword')?.touched && resetPasswordForm.get('confirmPassword')?.touched)"
          class="p-error">
          {{'ERRORS.PASSWORDS_DO_NOT_MATCH' | translate}}
        </small>
      </div>

      <p-button
        class="login-button"
        (onClick)="onSubmit()"
        label="{{'COMMON.SEND' | translate}}"
        [disabled]="resetPasswordForm.invalid || !resetPasswordForm.dirty"
      ></p-button>
    </form>
  </div>
</div>
<p-toast />

