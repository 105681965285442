import { animate, state, style, transition, trigger } from "@angular/animations";
import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircleQuestion, faImage } from "@fortawesome/free-regular-svg-icons";
import { faAnglesLeft, faAnglesRight, faAtom, faFlask, faGraduationCap, faUsers, faRocket, faPenNib, IconDefinition, faFingerprint, faHouse, faUser, faPowerOff, faGear } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { TagModule } from "primeng/tag";
import { AuthService } from "src/app/services/auth.service";
import { NavBarService } from "src/app/services/nav-bar.service";
import { NavListenerService } from "src/app/services/nav-listener.service";
import { UserService } from "src/app/services/user.service";
import { UtilsService } from "src/app/services/utils.service";
import { IntDropdownData } from "../form-generator/form-generator.component";
import { FormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatIconModule,
    TranslateModule,
    TagModule,
    ButtonModule,
    DropdownModule,
    FormsModule
  ],
  animations: [
    trigger('slideMenu', [
      state('false', style({
        width: '280px',
      })),
      state('true', style({
        width: '80px',
      })),
      transition('true <=> false', animate('400ms ease-in-out'))
    ]),
    trigger('resizeText', [
      state('false', style({
        width: '200px',
      })),
      state('true', style({
        width: '0',
      })),
      transition('true <=> false', animate('400ms ease-in-out'))
    ])
  ]
})

export class NavBarComponent implements OnInit, OnDestroy {
  @Input() darkModeActive: boolean = true;
  private readonly destroy$ = new Subject<void>();
  faAnglesLeft = faAnglesLeft;
  faAnglesRight = faAnglesRight;
  faAtom = faAtom;
  faFlask = faFlask;
  faUsers = faUsers;
  faRocket = faRocket;
  faPenNib = faPenNib;
  faPowerOff = faPowerOff;
  faGraduationCap = faGraduationCap;
  closedNav: boolean = false;
  showBoxes: boolean = true;
  navItems: IntNavItem[];
  navIcons: { [key: string]: IconDefinition } = {
    faHouse: faHouse,
    faGraduationCap: faGraduationCap,
    faRocket: faRocket,
    faUsers: faUsers,
    faPenNib: faPenNib,
    faFingerprint: faFingerprint,
    faUser: faUser,
    faCircleQuestion: faCircleQuestion,
    faGear: faGear,
    faImage: faImage
  };
  isMenuOpen: boolean = false;
  tokens: number;
  languaugesOptions: IntDropdownData[];
  selectedLenguage: string;
  languageReady: boolean = false;
  

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly navBarService: NavBarService,
    private readonly navListenerService: NavListenerService,
    private readonly userService: UserService,
    private readonly utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.setLanguages();
    this.navBarService.getNavList()
    .pipe(takeUntil(this.destroy$))
    .subscribe(res => {
      const navItems: IntNavItem[] = [];
      res.forEach(navItem => {
        navItems.push({
          redirectTo: navItem.redirect_to,
          literal: navItem.literal,
          icon: this.navIcons[navItem.icon]
        });
      });
      this.navItems = navItems;
    });
    this.updateTokensAndLang();
    this.navListenerService.action$
    .pipe(takeUntil(this.destroy$))
    .subscribe((action) => {
      if(action === 'updateTokens') {
        this.updateTokensAndLang();
      }
      if(action === 'updateLang') {
        this.updateTokensAndLang();
      }
    });
  }

  setLanguages() {
    this.languaugesOptions = this.utilsService.mapLangs();
  }

  changeLanguage() {
    this.utilsService.changeLanguage(this.selectedLenguage);
    sessionStorage.setItem('lang', this.selectedLenguage);
    setTimeout(() => {
      this.setLanguages();
    }, 10);
  }

  toggleNav() {
    this.closedNav = !this.closedNav;
    if (this.showBoxes) {
      this.showBoxes = false
    } else {
      setTimeout(() => {
        this.showBoxes = true
      }, 400);
    }
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu(): void {
    this.isMenuOpen = false;
  }

  navigateTo(path: string, toggle?: boolean) {
    if (toggle) this.toggleMenu();

    this.router.navigateByUrl(path);
  }

  logout() {
    this.authService.logout();
  }

  updateTokensAndLang() {
    this.userService.getTokensAndLang()
    .pipe(takeUntil(this.destroy$))
    .subscribe(resp => {
      this.tokens = resp.tokens;
      if (!this.languageReady) {
        this.selectedLenguage = resp.lang;
        sessionStorage.setItem('lang', resp.lang);
        this.utilsService.changeLanguage(resp.lang);
        setTimeout(() => {
          this.setLanguages();
        }, 10);
        this.languageReady = true;
      } else {
        const lang = sessionStorage.getItem('lang') || 'es';
        this.selectedLenguage = lang;
      }
    });
  }

  upgradePlan() {
    this.closeMenu();
    this.utilsService.navigateTo('/user?status=subs');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface IntNavItem {
  redirectTo: string;
  literal: string;
  icon: IconDefinition;
}
