import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { UserService } from "src/app/services/user.service";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { UtilsService } from "src/app/services/utils.service";
import { Subject, takeUntil } from "rxjs";
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';



@Component({
    selector: 'unsubscribe-newsletter',
    templateUrl: './unsubscribe-newsletter.component.html',
    standalone: true,
    imports: [
      ButtonModule,
      TranslateModule,
      CommonModule,
      ToastModule,
      CheckboxModule,
      FormsModule
    ],
    providers: [MessageService]
})

export class UnsubscribeNewsletterComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  email: string = '';
  unsubscribedNewsletters = {
    marketing: false,
    info: false
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly userServie: UserService,
    private readonly messageService: MessageService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.email = this.route.snapshot.queryParamMap.get('email') ?? '';
    this.userServie.getUnsubscribedCategories(this.email).pipe(takeUntil(this.destroy$)).subscribe(resp => {
      resp.unsubscribed.forEach((unsub: string) => {
        this.unsubscribedNewsletters[unsub as keyof typeof this.unsubscribedNewsletters] = true;
      });
    })
  }

  onSubmit() {
    const params = {
      email: this.email,
      subscriptions: this.unsubscribedNewsletters
    }
    this.userServie.setUnsubscribedCategories(params).pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('OK_KO.UNSUBSCRIBE_OK'),
          life: 2000
        });
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('OK_KO.UNSUBSCRIBE_KO'),
          life: 2000
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
