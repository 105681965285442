<article class="page container container--centered register-page">
  <div class="page-header">
    <h1 class="title">{{'REGISTER.VIEW_TITLE' | translate}}</h1>
    <p-button label="{{'COMMON.BACK' | translate}}" (onClick)="utilsService.navigateTo('/login');" [outlined]="true"
      icon="pi pi-arrow-left" class="back-button" />
  </div>

  <div class="form-container" *ngIf="registerForm">
    <form [formGroup]="registerForm" autocomplete="off">
      <div class="form-row__2-col">
        <div class="p-field">
          <label for="firstName">{{'COMMON.NAME' | translate}}</label>
          <input pInputText id="firstName" formControlName="firstName" placeholder="{{'COMMON.NAME' | translate}}" />
          <small *ngIf="registerForm.get('firstName')?.invalid && registerForm.get('firstName')?.touched"
            class="p-error">
            {{'ERRORS.REQUIRED_FIELD' | translate}}
          </small>
        </div>

        <div class="p-field">
          <label for="surnames">{{'COMMON.SURNAMES' | translate}}</label>
          <input pInputText id="surnames" formControlName="surnames" placeholder="{{'COMMON.SURNAMES' | translate}}" />
          <small *ngIf="registerForm.get('surnames')?.invalid && registerForm.get('surnames')?.touched" class="p-error">
            {{'ERRORS.REQUIRED_FIELD' | translate}}
          </small>
        </div>

        <div class="p-field">
          <label for="email">{{'COMMON.EMAIL' | translate}}</label>
          <input pInputText id="userEmail" formControlName="userEmail" autocomplete="off"
            placeholder="{{'COMMON.EMAIL' | translate}}" [attr.readonly]="true" (focus)="removeReadonly('userEmail')" />
          <small *ngIf="registerForm.get('userEmail')?.errors?.['required'] && registerForm.get('userEmail')?.touched"
            class="p-error">
            {{'ERRORS.REQUIRED_FIELD' | translate}}
          </small>
          <small *ngIf="registerForm.get('userEmail')?.errors?.['email'] && registerForm.get('userEmail')?.touched"
            class="p-error">
            {{'ERRORS.INVALID_EMAIL' | translate}}
          </small>
        </div>

        <div class="p-field">
          <label for="birthdate">{{'COMMON.BIRTHDATE' | translate}}</label>
          <p-calendar  [style]="{'width':'100%'}" id="birthdate" formControlName="birthdate" [showIcon]="true" dateFormat="dd/mm/yy"
            placeholder="{{'COMMON.BIRTHDATE' | translate}}"></p-calendar>
          <small *ngIf="registerForm.get('birthdate')?.invalid && registerForm.get('birthdate')?.touched"
            class="p-error">
            {{'ERRORS.REQUIRED_FIELD' | translate}}
          </small>
        </div>

        <div class="p-field">
          <label for="userPassword">{{'COMMON.PASSWORD' | translate}}</label>
          <p-password
            formControlName="userPassword"
            [toggleMask]="true"
            autocomplete="off"
            placeholder="{{'COMMON.PASSWORD' | translate}}"
            promptLabel="{{'REGISTER.ENTER_PASSWORD' | translate}}"
            weakLabel="{{'REGISTER.WEAK_PASSWORD' | translate}}"
            mediumLabel="{{'REGISTER.MEDIUM_PASSWORD' | translate}}"
            strongLabel="{{'REGISTER.STRONG_PASSWORD' | translate}}"
          >
            <ng-template pTemplate="footer">
              <p-divider />
              <p class="mt-2">{{"REGISTER.REQUIREMENTS" | translate}}</p>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>{{"REGISTER.UPPERCASE_LOWERCASE_REQUIREMENT" | translate}}</li>
                <li>{{"REGISTER.NUMBER_REQUIREMENT" | translate}}</li>
                <li>{{"REGISTER.SYMBOL_REQUIREMENT" | translate}}</li>
                <li>{{"REGISTER.MINIMUM_8_CHAR" | translate}}</li>
              </ul>
            </ng-template>
          </p-password>
          <small
            *ngIf="registerForm.get('userPassword')?.errors?.['required'] && registerForm.get('userPassword')?.touched"
            class="p-error"
          >
            {{'ERRORS.REQUIRED_FIELD' | translate}}
          </small>
          <small
            *ngIf="registerForm.get('userPassword')?.errors?.['passwordStrength']"
            class="p-error"
          >
            {{'ERRORS.PASSWORD_STRENGTH' | translate}}
          </small>
        </div>

        <div class="p-field">
          <label for="confirmPassword">{{'COMMON.CONFIRM_PASSWORD' | translate}}</label>
          <p-password
            formControlName="confirmPassword"
            [toggleMask]="true"
            autocomplete="off"
            placeholder="{{'COMMON.CONFIRM_PASSWORD' | translate}}"
            [feedback]="false"
          ></p-password>
          <small *ngIf="registerForm.get('confirmPassword')?.invalid && registerForm.get('confirmPassword')?.touched"
            class="p-error">
            {{'ERRORS.REQUIRED_FIELD' | translate}}
          </small>
          <small
            *ngIf="registerForm.errors?.['passwordsMismatch'] && (registerForm.get('userPassword')?.touched && registerForm.get('confirmPassword')?.touched)"
            class="p-error">
            {{'ERRORS.PASSWORDS_DO_NOT_MATCH' | translate}}
          </small>
        </div>
      </div>

      <div class="user-profile__footer">
        <button pButton type="submit" label="{{'REGISTER.REGISTER' | translate}}"
          [disabled]="registerForm.invalid || !registerForm.dirty" (click)="onSubmit()"></button>
      </div>
    </form>
  </div>
</article>
<p-toast />
