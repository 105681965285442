import { Injectable, Type, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalData: {
    component: Type<any>;
    data?: any;
    outputs?: { [key: string]: (event: any) => void };
    header?: string;
  };

  public modalVisibility: EventEmitter<boolean> = new EventEmitter();

  openModal(
    component: Type<any>,
    data?: any,
    outputs?: { [key: string]: (event: any) => void },
    header?: string
  ) {
    this.modalData = { component, data, outputs, header };
    this.modalVisibility.emit(true);
  }

  closeModal() {
    this.modalVisibility.emit(false);
  }

  getModalData() {
    return this.modalData;
  }
}
