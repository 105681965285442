const baseUrl = 'https://dev.api.easy-task.ai';
export const environment = {
  production: true,
  api_base_url: baseUrl,
  api_url: `${baseUrl}/api/`,
  auth_url: `${baseUrl}/oauth/`,
  storage_url: `${baseUrl}/storage/`,
  client_secret: 'IO9rSFjnfwLCJXS9JcYhcwea5JF8UpayHsJMGUit',
  client_id: 1,
  stripePubslicKey: 'pk_test_51Q9qwNApZyEIK3kvlaqg1meTivyhpzyebJ1oo1H0oHX4bSS52SAIHsAIXtumU3LRQ8l3jLzjyC6MOC0E8mmw8xJb00xT9S9LxJ'
};

