import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { PasswordModule } from "primeng/password";
import { UserService } from "src/app/services/user.service";
import { DividerModule } from 'primeng/divider';
import { passwordMatchValidator, passwordStrengthValidator } from "../register/register.component";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { UtilsService } from "src/app/services/utils.service";


@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    standalone: true,
    imports: [
      InputTextModule,
      ButtonModule,
      PasswordModule,
      ReactiveFormsModule,
      TranslateModule,
      CommonModule,
      DividerModule,
      ToastModule
    ],
    providers: [MessageService]
})

export class ResetPasswordComponent implements OnInit {
  token: string = '';
  email: string = '';
  resetPasswordForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private userServie: UserService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token') || '';
    this.email = this.route.snapshot.queryParamMap.get('email') || '';
    this.resetPasswordForm = this.fb.group({
      userPassword: ['', [Validators.required, passwordStrengthValidator()]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: passwordMatchValidator // Aquí aplicamos el validador personalizado
    });
    this.resetPasswordForm.reset();
    setTimeout(() => {
      this.resetPasswordForm.patchValue({
        userEmail: '',
        userPassword: '',
        confirmPassword: ''
      });
    }, 50);
  }

  onSubmit(): void {
    const formValue = this.resetPasswordForm.value;
    if (formValue.userPassword && formValue.confirmPassword &&  formValue.userPassword === formValue.confirmPassword) {
      const params = {
        token: this.token,
        email: this.email,
        password: formValue.userPassword,
        password_confirmation: formValue.confirmPassword
      }

      this.userServie.changePassword(params).subscribe(
        () => {
          this.messageService.add({ severity: 'success', summary: this.translate.instant('OK_KO.CHANGE_PASSWORD_OK')});
          this.utilsService.navigateTo('/login');
        },
        () => {
          this.messageService.add({ severity: 'error', summary: this.translate.instant('OK_KO.CHANGE_PASSWORD_KO')});
        }
      );
    } else {
      this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.PASSWORD_DONT_MATCH')});
    }
  }
}
