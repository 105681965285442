import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { Subject, takeUntil } from "rxjs";
import { IntDropdownData } from "src/app/components/form-generator/form-generator.component";
import { IntUserRegister, UserService } from "src/app/services/user.service";
import { UtilsService } from "src/app/services/utils.service";

export function passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
  const userPassword = control.get('userPassword')?.value;
  const confirmPassword = control.get('confirmPassword')?.value;

  if (userPassword !== confirmPassword) {
    return { passwordsMismatch: true };
  }

  return null;
}

export function passwordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[_!¡?¿()\]\[\{\}\\<>|/@#$%^&*,.'":-=+~`€£¥•]/.test(value);
    const hasMinLength = value.length >= 8;

    const passwordValid = hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && hasMinLength;
    return !passwordValid
      ? {
        passwordStrength: {
          hasUpperCase,
          hasLowerCase,
          hasNumber,
          hasSpecialChar,
          hasMinLength,
        },
      }
      : null;
  };
}

export function emailDomainValidator(control: AbstractControl): ValidationErrors | null {
  const forbiddenDomains = ['hotmail', 'outlook'];
  const email = control.value;
  if (email) {
    const domain = email.split('@')[1]?.split('.')[0];
    if (domain && forbiddenDomains.includes(domain.toLowerCase())) {
      return { forbiddenDomain: true };
    }
  }
  return null;
}

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
})
export class RegisterViewComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  registerForm: FormGroup;
  languaugesOptions: IntDropdownData[];

  constructor(
    public utilsService: UtilsService,
    private readonly fb: FormBuilder,
    private readonly messageService: MessageService,
    private readonly translate: TranslateService,
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    this.setLanguages();
    this.registerForm = this.fb.group({
      firstName: ['', Validators.required],
      surnames: ['', Validators.required],
      userEmail: ['', [Validators.required, Validators.email, emailDomainValidator]],
      birthdate: [null, [Validators.required]],
      userPassword: ['', [Validators.required, passwordStrengthValidator()]],
      confirmPassword: ['', Validators.required],
      language: ['']
    }, {
      validator: passwordMatchValidator // Aquí aplicamos el validador personalizado
    });
    this.registerForm.reset();
    this.registerForm.get('language')?.setValue('es');
    setTimeout(() => {
      this.registerForm.patchValue({
        userEmail: '',
        userPassword: '',
        confirmPassword: ''
      });
    }, 50);
  }

  setLanguages() {
    this.languaugesOptions = this.utilsService.mapLangs();
  }

  changeLang() {
    this.utilsService.changeLanguage(this.registerForm.get('language')?.value);
    setTimeout(() => {
      this.setLanguages();
    }, 10);
  }

  removeReadonly(field: string): void {
    const input = document.querySelector(`input[formControlName="${field}"]`);
    if (input) {
      input.removeAttribute('readonly');
    }
  }

  onSubmit() {
    const formValue = this.registerForm.value;
    const birthdate = formValue.birthdate;
    let formattedBirthdate: string | null = null;
    if (birthdate) {
      const date = new Date(birthdate);
      formattedBirthdate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
    const params: IntUserRegister = {
      name: formValue.firstName,
      surnames: formValue.surnames,
      email: formValue.userEmail,
      birthdate: formattedBirthdate as string,
      password: formValue.userPassword,
      language: formValue.language
    };
    this.userService.register(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.utilsService.navigateTo('/login?message=ACCOUNT_CREATED');
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('OK_KO.REGISTER_KO'),
            life: 2000
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
