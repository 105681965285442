<nav class="navbar" [ngClass]="{ close: closedNav }" [@slideMenu]="closedNav">
  <div class="navbar__item" >
    <div class="navbar__item-icon" (click)="navigateTo('/')" >
      <img
        *ngIf="darkModeActive"
        class="logo-img"
        src="/assets/images/logo_degradado.png"
        alt="image"
      />
      <img
        *ngIf="!darkModeActive"
        class="logo-img"
        src="/assets/images/LOGO(NEGRO).svg"
        alt="image"
      />
    </div>
    <div class="navbar__item-text cursor-pointer" (click)="navigateTo('/')" [@resizeText]="closedNav">
      <img
        class="logo-img"
        src="/assets/images/logo_titulo_degradado.png"
        alt="image"
      />
    </div>
  </div>

  <div class="navbar__item" [@slideMenu]="closedNav" >
    <div class="navbar__item-icon cursor-pointer" (click)="toggleNav()">
      <fa-icon *ngIf="!closedNav" [icon]="faAnglesLeft"></fa-icon>
      <fa-icon *ngIf="closedNav" [icon]="faAnglesRight"></fa-icon>
    </div>
    <div class="navbar__item-text  cursor-pointer" (click)="toggleNav()" [@resizeText]="closedNav">
      <span class="title nav">{{'COMMON.COLLAPSE' | translate}}</span>
    </div>
  </div>

  <div class="navbar__item" *ngFor="let item of navItems">
    <div class="navbar__item-icon cursor-pointer" (click)="navigateTo(item.redirectTo)">
      <fa-icon [icon]="item.icon"></fa-icon>
    </div>
    <div class="navbar__item-text cursor-pointer" (click)="navigateTo(item.redirectTo)" [@resizeText]="closedNav">
      <span class="title nav">{{item.literal| translate}}</span>
    </div>
  </div>

  <div class="navbar__item">
    <div class="navbar__item-icon cursor-pointer" (click)="logout()">
      <fa-icon [icon]="faPowerOff"></fa-icon>
    </div>
    <div class="navbar__item-text cursor-pointer" (click)="logout()" [@resizeText]="closedNav">
      <span class="title nav">{{'COMMON.LOGOUT' | translate}}</span>
    </div>
  </div>
</nav>
<nav class="mobile-navbar">
  <div class="navbar-container">
    <div class="logo" (click)="navigateTo('/')">
      <img
        class="logo-icon"
        src="/assets/images/logo_degradado.png"
        alt="image"
      />
      <img
        class="logo-text"
        src="/assets/images/logo_titulo_degradado.png"
        alt="image"
      />
    </div>
    <div (click)="toggleMenu()" id="nav-icon2" [ngClass]="{'open': isMenuOpen}">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <ul class="menu" [class.open]="isMenuOpen">
    <li *ngFor="let item of navItems">
      <div (click)="navigateTo(item.redirectTo, true)">
        <fa-icon [icon]="item.icon"></fa-icon><span class="title nav">{{item.literal| translate}}</span>
      </div>
    </li>
  </ul>
</nav>
