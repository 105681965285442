import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { UtilsService } from "src/app/services/utils.service";
import { DividerModule } from 'primeng/divider';
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { UserService } from "src/app/services/user.service";
import { Subject, takeUntil } from "rxjs";


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    InputTextModule,
    ButtonModule,
    PasswordModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    DividerModule,
    ToastModule
  ],
  providers: [MessageService]
})

export class LoginComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  showRecover = false;
  loadingRecover = false;
  loginForm: FormGroup = new FormGroup({
    email: new FormControl<string | null>(null),
    password: new FormControl<string | null>(null)
  });

  recoverForm: FormGroup = new FormGroup({
    recover: new FormControl<string | null>(null)
  });

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public utilsService: UtilsService,
    private readonly messageService: MessageService,
    private readonly translate: TranslateService,
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    this.route.queryParams
    .pipe(takeUntil(this.destroy$))
    .subscribe((params) => {
      const token = params['access_token'];
      if (token) {
        this.navigate(token);
      }
      if (params['message'] === 'ACCOUNT_CREATED') {
        this.accountCreated();
      }
    });
  }


  toggleRecover() {
    this.showRecover = !this.showRecover;
  }

  onSubmit() {
    this.authService.login(this.loginForm.value)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (res) => {
        this.navigate(res.access_token);
      },
      error: (error) => {
        if (error.status === 403 && error.error.error === 'Email not verified.') {
          this.messageService.add({ severity: 'error', summary: this.translate.instant('OK_KO.EMAIL_NOT_VERIFIED') });
        } else if (error.error.error === 'invalid_grant') {
          this.messageService.add({ severity: 'error', summary: this.translate.instant('OK_KO.LOGIN_INVALID_GRANT') });
        } else {
          this.messageService.add({ severity: 'error', summary: this.translate.instant('OK_KO.LOGIN_ERROR') }); 
        }
      }
    });
  }

  navigate(token: string) {
    this.authService.saveToken(token);
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      localStorage.removeItem('redirectUrl');
      this.router.navigateByUrl(redirectUrl);
    } else {
      this.router.navigate(['/']);
    }
  }

  loginWithGoogle(): void {
    this.authService.loginWithGoogle();
  }

  recoverPass() {
    this.loadingRecover = true;
    this.userService.changePasswordEamil({email: (this.recoverForm.get('recover')?.value || '')})
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: () => {
        this.afterRecover();
      },
      error: () => {
        this.afterRecover();
      }
    });
  }

  accountCreated() {
    setTimeout(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('OK_KO.REGISTER_OK'),
        life: 2000
      });
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { message: null },
        queryParamsHandling: 'merge'
      });
    }, 0);
    
  }

  afterRecover() {
    this.messageService.add({
      severity: 'success',
      summary: this.translate.instant('OK_KO.CHANGE_PASSWORD_EMAIL_OK'),
      life: 2000
    });
    this.toggleRecover();
    this.loadingRecover = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
