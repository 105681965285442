import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { GuestGuardService } from './services/guest-guard.service';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { VerifyEmailComponent } from './views/verify-email/verify-email.component';
import { UnsubscribeNewsletterComponent } from './views/unsubscribe-newsletter/unsubscribe-newsletter.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [ GuestGuardService ]
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    canActivate: [ GuestGuardService ]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [ GuestGuardService ]
  },
  {
    path: 'unsubscribe-newsletter',
    component: UnsubscribeNewsletterComponent,
    canActivate: [ GuestGuardService ]
  },
  {
    path: 'register',
    loadChildren: () => import('./views/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'education',
    loadChildren: () => import('./views/education/education.module').then(m => m.EducationModule)
  },
  {
    path: 'productivity',
    loadChildren: () => import('./views/productivity/productivity.module').then(m => m.ProductivityModule)
  },
  {
    path: 'social-media',
    loadChildren: () => import('./views/social-media/social-media.module').then(m => m.SocialMediaModule)
  },
  {
    path: 'writing',
    loadChildren: () => import('./views/writing/writing.module').then(m => m.WritingViewModule)
  },
  {
    path: 'ai-detection',
    loadChildren: () => import('./views/ai-detection/ai-detection.module').then(m => m.AiDetectionModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./views/user-profile/user-profile.module').then(m => m.UserProfileViewModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./views/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'admin-settings',
    loadChildren: () => import('./views/admin-settings/admin-settings.module').then(m => m.AdminSettingsModule)
  },
  {
    path: 'images',
    loadChildren: () => import('./views/images/images.module').then(m => m.ImagesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
